import { string } from "prop-types";
import React from "react";

const Plus = ({ color = "currentColor", className, ...props }) => (
  <svg viewBox="0 0 50 50" className={className} {...props}>
    <line
      fill="none"
      stroke={color}
      strokeWidth="4"
      x1="9"
      x2="41"
      y1="25"
      y2="25"
    />
    <line
      fill="none"
      stroke={color}
      strokeWidth="4"
      x1="25"
      x2="25"
      y1="9"
      y2="41"
    />
  </svg>
);

Plus.propTypes = {
  color: string,
  className: string,
};

export default Plus;
