import styled from "@emotion/styled";
import { bool, oneOf, string } from "prop-types";
import React from "react";

import theme from "../../theme";
import icons from "../Icon/icons";

const Standard = styled.a`
  position: relative;
  display: inline-block;
  color: ${({ inverted }) =>
    inverted
      ? theme.color("socialmedia.light")
      : theme.color("socialmedia.base")};
  width: 36px;
  height: 36px;
  transition: ${theme.get("transition.default.transition")};
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ inverted }) =>
      inverted
        ? theme.color("socialmedia.base")
        : theme.color("socialmedia.light")};
    border-radius: 50%;
    transition: ${theme.get("transition.default.transition")};
  }
  &:hover {
    color: ${theme.color("socialmedia.light")};
    &:before {
      background-color: ${theme.color("socialmedia.base")};
    }
  }
  & > * {
    position: relative;
  }
`;

const Simple = styled.a`
  position: relative;
  display: inline-block;
  color: ${({ inverted }) =>
    inverted
      ? theme.color("socialmedia.dark")
      : theme.color("socialmedia.light")};
  width: 36px;
  height: 36px;
  transition: ${theme.get("transition.default.transition")};
  &:hover {
    color: ${theme.color("socialmedia.base")};
  }
  & > * {
    position: relative;
  }
`;

const IconType = {
  default: Standard,
  simple: Simple,
};

function SocialMedia({ link, aria, type, inverted, simple, ...props }) {
  const IconComp = icons[type];
  const Comp = IconType[simple ? "simple" : "default"];

  return (
    <Comp
      rel="noopener noreferrer"
      aria-label={aria ? aria : type}
      href={link}
      inverted={inverted}
      {...props}
    >
      <IconComp />
    </Comp>
  );
}

SocialMedia.propTypes = {
  type: oneOf(Object.keys(icons)).isRequired,
  aria: string,
  link: string.isRequired,
  inverted: bool,
  simple: bool,
};

SocialMedia.defaultProps = {
  inverted: false,
  simple: false,
};

export default SocialMedia;
