import { string } from "prop-types";
import React from "react";

function Arrow({ color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 50 50"
      className={`icon icon-arrow ${className}`}
      {...props}
    >
      <path
        d="M0,25A25,25,0,1,1,25,50,25,25,0,0,1,0,25Z"
        fill={color ? color : "currentColor"}
      />
      <path
        d="M15.5,34.5h5V32.39h-5Zm2-4.22h-5V28.17h5Zm-8-6.34v2.12h31V23.94Zm8-2.11h-5V19.72h5Zm-2-4.22h5V15.5h-5Z"
        fill="#fff"
      />
    </svg>
  );
}

Arrow.propTypes = {
  color: string,
  className: string,
};

Arrow.defaultProps = {
  color: null,
  className: "",
};

export default Arrow;
