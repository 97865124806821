import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { equals } from "ramda";
import React from "react";

import theme from "../../theme";
import { SkipToContentLink } from "../SkipToContent";
import { h5 } from "../Typography";

const List = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
`;

const ListItem = styled.li`
  ${h5};
  position: relative;
  padding-bottom: ${({ isSmall }) => (isSmall ? "4px" : "8px")};
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    transition: ${theme.transition()};
    background: ${theme.color("primary")};

    transform: scaleX(0);
    transform-origin: left;

    ${({ isActive, vertical }) =>
      isActive &&
      !vertical &&
      `
        transform: scaleX(1);
      `}
  }

  > * {
    text-decoration: none;
    color: ${theme.color("primary")};
    text-transform: inherit;
    transition: ${theme.transition()};
  }

  &:hover {
    > * {
      color: ${theme.color("primary.light")};
    }

    &::before {
      background: ${theme.color("primary.light")};
    }
  }
`;
function Navigation({
  items,
  vertical,
  active,
  onItemClick,
  isSmall,
  ...props
}) {
  return (
    <>
      <SkipToContentLink />
      <nav {...props}>
        <List vertical={vertical}>
          {items.map((item) => (
            <ListItem
              key={item.id}
              isSmall={isSmall}
              isActive={equals(item, active)}
              onClick={(e) => onItemClick(e, item.id)}
            >
              {item.render}
            </ListItem>
          ))}
        </List>
      </nav>
    </>
  );
}

export const navigationItem = PropTypes.shape({
  id: PropTypes.string,
  render: PropTypes.node,
});

Navigation.propTypes = {
  items: PropTypes.arrayOf(navigationItem),
  active: navigationItem,
  vertical: PropTypes.bool,
  onItemClick: PropTypes.func,
  isSmall: PropTypes.bool,
};

Navigation.defaultProps = {
  vertical: false,
  onItemClick: () => {},
};

export default Navigation;
