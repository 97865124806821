import { string } from "prop-types";
import React from "react";

function Linkedin({ color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 36 36"
      className={`icon icon-arrow ${className}`}
      {...props}
    >
      <g transform="translate(4.5, 4.5)">
        <path
          fill={color ? color : "currentColor"}
          d="M23.1,22.5H19v-6.4c0-1.5,0-3.5-2.1-3.5c-2.1,0-2.4,1.7-2.4,3.4v6.5h-4.1V9.3h3.9v1.8h0.1
						c0.5-1,1.9-2.1,3.9-2.1c4.1,0,4.9,2.7,4.9,6.3V22.5z M5.7,7.6c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4
						c1.3,0,2.4,1.1,2.4,2.4C8.1,6.5,7,7.6,5.7,7.6L5.7,7.6z M7.8,22.5H3.7V9.3h4.1V22.5z"
        />
      </g>
    </svg>
  );
}

Linkedin.propTypes = {
  color: string,
  className: string,
};

Linkedin.defaultProps = {
  color: null,
  className: "",
};

export default Linkedin;
