import { string } from "prop-types";
import React from "react";

function Bullet({ color, className, ...props }) {
  return (
    <svg
      height="20"
      viewBox="0 0 3 20"
      width="3"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon-bullet ${className}`}
      {...props}
    >
      <polygon
        className="cls-1"
        points="3 5 2 5 2 0 1 0 1 5 0 5 0 15 1 15 1 20 2 20 2 15 3 15 3 5"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

Bullet.propTypes = {
  color: string,
  className: string,
};

Bullet.defaultProps = {
  color: "currentColor",
  className: "",
};

export default Bullet;
