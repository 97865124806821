import React from "react";

function Logo() {
  return (
    <svg viewBox="0 0 181.43 156.83" xmlns="http://www.w3.org/2000/svg">
      <path d="m172.16 61.57-11.9-20.29h-16l8.45-14.34-11.91-20.28h-23.9l-6.9 13.44-7-13.44h-23.9l-7.77 13.25-8-13.25h-23.9l-11.9 20.28 8.47 14.34h-16l-11.93 20.29 12.1 20.53h15.31l-8.21 14 12.1 20.53h15.63l-8 13.55 12.1 20.54h23.7l7.24-12.23 7.23 12.23h23.71l12.09-20.54-8-13.55h15.66l12.27-20.54-8.21-14h15.32zm-35.82-38.49a4.44 4.44 0 1 1 -3.54-5.19 4.44 4.44 0 0 1 3.54 5.19z" />
    </svg>
  );
}

export default Logo;
