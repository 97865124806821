import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import { customMq } from "../../utils/mediaQuery";

const applyHideStyles = (Component, width, down) => styled(Component)`
  display: none;

  /* Width is decremented by 1 when 'down' is true. This prevents 
  the component from being rendered simultaneously with 
  components that don't have 'down' on true  */
  ${customMq(
    down ? parseInt(width) - 1 : parseInt(width),
    down ? "max" : "min"
  )} {
    display: block;
  }
`;

function Hide({ render: Component, width, down, ...props }) {
  const HiddenComponent = applyHideStyles(Component, width, down);
  return <HiddenComponent {...props} />;
}

Hide.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  down: PropTypes.bool,
  render: PropTypes.elementType.isRequired,
};

Hide.defaultProps = {
  down: false,
};

export default Hide;
