import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../theme";

const Wrapper = styled.div`
  display: flex;
  margin-bottom: ${theme.spacing("xxs")};
`;

const Input = styled.input`
  display: inline-block;
  height: 13px;
  width: 13px;
  position: relative;
  -webkit-appearance: none;
  background-color: ${({ checked }) => (checked ? "white" : "transparent")};
  border: 1px solid
    ${({ appearance }) =>
      appearance === "default"
        ? theme.color("text")
        : theme.color("text.light")};
  border-radius: 2px;
  outline: none;

  &:after {
    content: "✓";

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    font-size: 12px;
    font-weight: bold;
    color: ${theme.color("primary")};

    opacity: ${({ checked }) => (checked ? 1 : 0)};
    transition: opacity 120ms ease-out;
  }
`;

const Label = styled.label`
  margin-left: ${theme.space("xxs")};
  font-family: ${theme.get("type.fontFamily.secondary")};
  font-size: ${theme.fs("base")};
  color: ${({ appearance }) =>
    theme.color(appearance === "default" ? "text" : "text.light")};
`;

function Checkbox({
  id,
  label,
  checked,
  inputProps,
  onChange,
  appearance,
  ...props
}) {
  return (
    <Wrapper {...props}>
      <Input
        type="checkbox"
        id={id}
        name={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked, e)}
        appearance={appearance}
        {...inputProps}
      />
      {label && (
        <Label htmlFor={id} appearance={appearance}>
          {label}
        </Label>
      )}
    </Wrapper>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  checked: PropTypes.bool,
  inputProps: PropTypes.any,
  onChange: PropTypes.func,
  appearance: PropTypes.oneOf(["default", "light"]),
};

Checkbox.defaultProps = {
  checked: false,
  onChange: () => {},
  appearance: "default",
};

export default Checkbox;
