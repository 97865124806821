import { string } from "prop-types";
import React from "react";

function Twitter({ color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 36 36"
      className={`icon icon-arrow ${className}`}
      {...props}
    >
      <g transform="translate(4.5, 4.5)">
        <path
          fill={color ? color : "currentColor"}
          d="M24.2,7.1c-0.8,0.3-1.6,0.6-2.5,0.7c0.9-0.5,1.6-1.4,1.9-2.4c-0.8,0.5-1.8,0.8-2.8,1
						c-0.8-0.8-1.9-1.3-3.2-1.3c-2.4,0-4.4,1.9-4.4,4.3c0,0.3,0,0.7,0.1,1c-3.6-0.2-6.9-1.9-9-4.5C3.9,6.5,3.7,7.2,3.7,8
						c0,1.5,0.8,2.8,2,3.5c-0.7,0-1.4-0.2-2-0.5c0,0,0,0,0,0.1c0,2.1,1.5,3.8,3.5,4.2c-0.4,0.1-0.8,0.1-1.2,0.1
						c-0.3,0-0.6,0-0.8-0.1c0.6,1.7,2.2,2.9,4.1,3c-1.5,1.1-3.4,1.8-5.4,1.8c-0.4,0-0.7,0-1-0.1c1.9,1.2,4.2,1.9,6.7,1.9
						c8.1,0,12.5-6.5,12.5-12.1c0-0.2,0-0.4,0-0.6C22.9,8.7,23.6,7.9,24.2,7.1"
        />
      </g>
    </svg>
  );
}

Twitter.propTypes = {
  color: string,
  className: string,
};

Twitter.defaultProps = {
  color: null,
  className: "",
};

export default Twitter;
