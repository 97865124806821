import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import React, { useEffect, useState } from "react";

import useCookies from "../../hooks/useCookies";
import CookieWarning from "../CookieWarning";
import Heading from "../Heading";
import Text from "../Text";

const message = (
  <>
    <Heading size={3}>Cookkkiesss!</Heading>
    <Text html>
      {
        '<p>Met functionele en analytische cookies maken wij onze website vriendelijker voor jou als gebruiker - hebben we alleen even een vinkje voor nodig! Hier check je ons <a href="/privacy">privacybeleid</a>.</p>'
      }
    </Text>
  </>
);

const gaCookie = "gatsby-gdpr-google-tagmanager";

const Cookies = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [cookie, setCookie] = useCookies(gaCookie);

  const accept = {
    label: "Love cookies",
    action: () => {
      setCookie(gaCookie, "true", 60);
      setShowBanner(false);
      // eslint-disable-next-line
      if (typeof window !== undefined) {
        initializeAndTrack(window.location);
      }
    },
  };

  const reject = {
    label: "Liever niet",
    action: () => {
      setShowBanner(false);
    },
  };

  useEffect(() => {
    setShowBanner(!cookie);
  }, [cookie]);

  return (
    showBanner && (
      <CookieWarning message={message} accept={accept} reject={reject} />
    )
  );
};

export default Cookies;
