import { css } from "@emotion/core";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";

export const bodyLarge = css`
  font-size: ${theme.fs("bodyLarge")};
  line-height: ${theme.lh("bodyLarge")};
`;

export const bodySmall = css`
  font-size: ${theme.fs("bodySmall")};
  line-height: ${theme.lh("bodySmall")};
`;

export const ctaLarge = css`
  font-size: ${theme.fs("ctaLarge")};
  line-height: ${theme.lh("ctaLarge")};

  ${mq("2")} {
    font-size: ${theme.fs("desktop.ctaLarge")};
    line-height: ${theme.lh("desktop.ctaLarge")};
  }
  text-decoration: underline;
`;

export const ctaSmall = css`
  font-size: ${theme.fs("ctaSmall")};
  line-height: ${theme.lh("ctaSmall")};
  text-transform: uppercase;
  text-decoration: underline;
`;

export const caption = css`
  font-family: ${theme.family("primary")};
  font-size: ${theme.fs("caption")};
  line-height: ${theme.lh("caption")};
  letter-spacing: ${theme.letterspacing("caption")};
  text-transform: uppercase;
`;

export const h1 = css`
  font-family: ${theme.family("primary")};
  font-weight: ${theme.weight("primary.black")};
  font-size: ${theme.fs("h1")};
  line-height: ${theme.lh("h1")};
  letter-spacing: ${theme.letterspacing("h1")};

  ${mq("3")} {
    font-size: ${theme.lh("desktop.h1")};
    line-height: ${theme.lh("desktop.h1")};
    letter-spacing: ${theme.letterspacing("desktop.h1")};
  }
`;

export const h2 = css`
  font-family: ${theme.family("primary")};
  font-weight: ${theme.weight("primary.black")};
  font-size: ${theme.fs("h2")};
  line-height: ${theme.lh("h2")};
  letter-spacing: ${theme.letterspacing("h2")};

  ${mq("3")} {
    letter-spacing: ${theme.letterspacing("desktop.h2")};
    line-height: ${theme.lh("desktop.h2")};
    font-size: ${theme.lh("desktop.h2")};
  }
`;

export const h3 = css`
  font-family: ${theme.family("primary")};
  line-height: ${theme.lh("h3")};
  font-size: ${theme.fs("h3")};

  ${mq("3")} {
    line-height: ${theme.lh("desktop.h3")};
    font-size: ${theme.fs("desktop.h3")};
  }
`;

export const h4 = css`
  font-size: ${theme.fs("h4")};
  line-height: ${theme.lh("h4")};
  font-weight: ${theme.weight("primary.regular")};
  text-transform: none;
  color: ${theme.color("text.base")};
`;

export const h5 = css`
  font-family: ${theme.family("primary")};
  font-weight: ${theme.weight("primary.bold")};
  font-size: ${theme.fs("h5")};
  line-height: ${theme.lh("h5")};
  letter-spacing: ${theme.letterspacing("h5")};
  text-transform: uppercase;
`;

export const bgTitle = css`
  font-family: ${theme.get("type.fontFamily.tertiary")};
  font-size: ${theme.fs("bgTitle")};
  line-height: ${theme.lh("bgTitle")};
  ${mq("3")} {
    font-size: ${theme.fs("desktop.bgTitle")};
    line-height: ${theme.lh("desktop.bgTitle")};
  }
`;

export const pillButton = css`
  font-family: ${theme.family("secondary")};
  font-size: ${theme.fs("desktop.h3")};
  line-height: ${theme.lh("desktop.h3  ")};
`;

export const rotatingButton = css`
  font-family: ${theme.family("primary")};
  font-size: ${theme.fs("rotatingButton")};
  line-height: ${theme.lh("rotatingButton")};
  font-weight: ${theme.weight("primary.bold")};
  text-transform: uppercase;
  letter-spacing: 3px;
`;

export const ContactHeading = css`
  font-family: ${theme.family("secondary")};
  font-size: ${theme.fs("desktop.HeadingLarge")};
  line-height: 1;
  font-weight: ${theme.weight("secondary.regular")};
  text-transform: none;
  color: ${theme.color("text.base")};
`;

export const footerNavigation = css`
  font-family: ${theme.family("secondary")};
  font-weight: ${theme.weight("secondary.regular")};
  font-size: ${theme.fs("h4")};
  line-height: ${theme.lh("h4")};
`;
