import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import React from "react";

import { TRANSITION_DURATION } from "../../constants";

const Transition = ({ children, location }) => {
  const duration = TRANSITION_DURATION / 1000;

  const variants = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        duration: duration,
        delay: duration,
        when: "beforeChildren",
      },
    },
    exit: {
      opacity: 0,
      transition: { duration: duration },
    },
  };

  const ContentWrapper = styled(motion.div)`
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
  `;

  return (
    <AnimatePresence>
      <ContentWrapper
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
        key={location.pathname}
      >
        {children}
      </ContentWrapper>
    </AnimatePresence>
  );
};

Transition.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
};

export default Transition;
