import { useEffect, useState } from "react";

/**
 * The useSkipLinks hook gets all the elements that carry the data-skip-link attribute
 * together with the element's id. If an id is missing the element will be skipped.
 */

export default function useSkipLinks() {
  const [skipLinks, setSkipLinks] = useState([]);

  useEffect(() => {
    const skipLinkElements = document.querySelectorAll("[data-skip-link]");

    const links = Array.from(skipLinkElements, (skipLink) => {
      return {
        title: skipLink.dataset.skipLink,
        id: skipLink.id,
      };
    }).filter((n) => n.id);

    setSkipLinks(links);
  }, []);

  return { skipLinks };
}
