import { string } from "prop-types";
import React from "react";

function ArrowLarge({ color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 57 57"
      className={`icon icon-arrow ${className}`}
      {...props}
    >
      <g>
        <g>
          <path
            fill={color ? color : "currentColor"}
            d="M28.5,57C12.8,57,0,44.2,0,28.5S12.8,0,28.5,0S57,12.8,57,28.5S44.2,57,28.5,57z M28.5,2
					C13.9,2,2,13.9,2,28.5S13.9,55,28.5,55S55,43.1,55,28.5S43.1,2,28.5,2z"
          />
        </g>
        <g>
          <path
            fill={color ? color : "currentColor"}
            className="arrow_large_inner_arrow"
            d="M22.5,37.5h-2v-5h2V37.5z M26.5,40.5h-2v-5h2V40.5z M30.5,43.5h-2v-31h2V43.5z M34.5,40.5h-2v-5h2V40.5z
            M38.5,37.5h-2v-5h2V37.5z"
          />
        </g>
      </g>
    </svg>
  );
}

ArrowLarge.propTypes = {
  color: string,
  className: string,
};

ArrowLarge.defaultProps = {
  color: null,
  className: "",
};

export default ArrowLarge;
