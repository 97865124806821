import { string } from "prop-types";
import React from "react";

const Minus = ({ color = "currentColor", className, ...props }) => (
  <svg viewBox="0 0 50 50" className={className} {...props}>
    <line
      fill="none"
      stroke={color}
      strokeWidth="4"
      x1="9"
      x2="41"
      y1="25"
      y2="25"
    />
  </svg>
);

Minus.propTypes = {
  color: string,
  className: string,
};

export default Minus;
