import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { node, oneOf } from "prop-types";
import { isNil } from "ramda";
import React from "react";

import theme from "../../theme";
import { h1, h2, h3, h4, h5 } from "../Typography";

const baseCSS = css`
  margin: 0;
  padding: 0;
  text-transform: uppercase;
`;

const overwriteColor = ({ color }) =>
  !isNil(color)
    ? css`
        color: ${theme.color(color) || color};
      `
    : undefined;

const Heading1 = styled.h1`
  ${baseCSS};
  ${h1};
  color: ${theme.color("text.base")};

  ${overwriteColor};
`;

const Heading2 = styled.h2`
  ${baseCSS};
  ${h2};
  font-weight: ${({ weight }) =>
    weight ? theme.weight(`primary.${weight}`) : theme.weight("primary.black")};
  color: ${theme.color("text.base")};

  ${overwriteColor};
`;

const Heading3 = styled.h3`
  ${baseCSS};
  ${h3};
  font-weight: ${({ weight }) =>
    weight ? theme.weight(`primary.${weight}`) : theme.weight("primary.bold")};

  ${overwriteColor};
`;

const Heading4 = styled.h4`
  ${baseCSS};
  ${h4};
  text-transform: none;

  ${overwriteColor};
`;

const Heading5 = styled.h5`
  ${baseCSS};
  ${h5};
  text-transform: none;
  color: ${theme.color("text.base")};

  ${overwriteColor};
`;

const headingMap = {
  1: Heading1,
  2: Heading2,
  3: Heading3,
  4: Heading4,
  5: Heading5,
};

function Heading({ children, size, weight, color, ...props }) {
  const Wrapper = headingMap[size];

  return (
    <Wrapper color={color} weight={weight} {...props}>
      {children}
    </Wrapper>
  );
}

Heading.propTypes = {
  children: node,
  size: oneOf([1, 2, 3, 4, 5]),
  weight: oneOf(["bold", "black"]),
  color: oneOf(["primary", "secondary", "text.light", "text.dark"]),
};

Heading.defaultProps = {
  size: 1,
};

export default Heading;
