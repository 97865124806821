import { string } from "prop-types";
import React from "react";

function Arrow({ color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 31 19"
      className={`icon icon-arrow ${className}`}
      {...props}
    >
      <g>
        <g>
          <path
            fill={color ? color : "currentColor"}
            d="M25,16.9V19h-5v-2.1H25z M28,12.7v2.1h-5v-2.1H28z M31,8.4v2.1H0V8.4H31z M28,4.2v2.1h-5V4.2 H28z M25,0v2.1h-5V0H25z"
          />
        </g>
        <g>
          <path
            fill={color ? color : "currentColor"}
            d="M25,16.9V19h-5v-2.1H25z M28,12.7v2.1h-5v-2.1H28z M31,8.4v2.1H0V8.4H31z M28,4.2v2.1h-5V4.2 H28z M25,0v2.1h-5V0H25z"
          />
        </g>
      </g>
    </svg>
  );
}

Arrow.propTypes = {
  color: string,
  className: string,
};

Arrow.defaultProps = {
  color: null,
  className: "",
};

export default Arrow;
