import parseHex from "pure-color/parse/hex";
import { __, concat, curry, join, pipe } from "ramda";

const arrayToRGBA = (str) => `rgba(${str})`;

const hexToRGBA = curry((opacity, hex) =>
  pipe(() => parseHex(hex), concat(__, [opacity]), join(", "), arrayToRGBA)()
);

export default hexToRGBA;
