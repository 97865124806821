import { css } from "@emotion/core";
import styled from "@emotion/styled";
import GatsbyLink from "gatsby-link";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../theme";
import { shouldTreatAsInternal, stripLink } from "./utils";

const LinkStyling = css`
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${theme.color("link.over")};
  }
`;

const LinkStyled = styled.a`
  ${LinkStyling};
`;

const GLink = styled(GatsbyLink)`
  ${LinkStyling};
`;

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  download,
  ...other
}) => {
  // Everything that will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  const hostname =
    typeof window !== "undefined" ? window.location.hostname : "";

  if (download) {
    return (
      <LinkStyled download={download} href={to} {...other}>
        {children}
      </LinkStyled>
    );
  }

  if (internal || shouldTreatAsInternal(hostname, to)) {
    return (
      <GLink
        to={shouldTreatAsInternal(hostname, to) ? stripLink(hostname, to) : to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GLink>
    );
  }

  return (
    <LinkStyled href={to} {...other}>
      {children}
    </LinkStyled>
  );
};

Link.propTypes = {
  to: PropTypes.string,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
  download: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.array,
  ]).isRequired,
};

export default Link;
