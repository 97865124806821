import { string } from "prop-types";
import React from "react";

function Next({ color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 31 31"
      className={`icon icon-next ${className}`}
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-399.000000, -2389.000000)">
          <g transform="translate(399.000000, 2389.000000)">
            <circle
              fill={color ? color : "currentColor"}
              cx="15.5"
              cy="15.5"
              r="15.5"
            ></circle>
            <polygon
              fill="#FFFFFF"
              transform="translate(16.043860, 15.228070) scale(1, -1) rotate(90.000000) translate(-16.043860, -15.228070) "
              points="15.5 6.79824561 16.5877193 6.79824561 16.5877193 23.6578947 15.5 23.6578947"
            ></polygon>
            <rect
              fill="#FFFFFF"
              x="20.122807"
              y="12.5087719"
              width="2.71929825"
              height="1.0877193"
            ></rect>
            <rect
              fill="#FFFFFF"
              x="20.122807"
              y="16.8596491"
              width="2.71929825"
              height="1.0877193"
            ></rect>
            <rect
              fill="#FFFFFF"
              x="18.4912281"
              y="10.3333333"
              width="2.71929825"
              height="1.0877193"
            ></rect>
            <rect
              fill="#FFFFFF"
              x="18.4912281"
              y="19.0350877"
              width="2.71929825"
              height="1.0877193"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
}

Next.propTypes = {
  color: string,
  className: string,
};

Next.defaultProps = {
  color: null,
  className: "",
};

export default Next;
