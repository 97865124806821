import Arrow from "./Arrow";
import ArrowLarge from "./ArrowLarge";
import ArrowSolo from "./ArrowSolo";
import Bullet from "./Bullet";
import Close from "./Close";
import Facebook from "./Facebook";
import Instagram from "./Instagram";
import Linkedin from "./Linkedin";
import Logo from "./Logo";
import LogoName from "./LogoName";
import Mail from "./Mail";
import MailOutline from "./MailOutline";
import Minus from "./Minus";
import Next from "./Next";
import Phone from "./Phone";
import PhoneOutline from "./PhoneOutline";
import Plus from "./Plus";
import SolidArrow from "./SolidArrow";
import Twitter from "./Twitter";

export const icons = {
  arrow: Arrow,
  arrowSolo: ArrowSolo,
  solidArrow: SolidArrow,
  next: Next,
  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
  linkedin: Linkedin,
  mail: Mail,
  mailOutline: MailOutline,
  phoneOutline: PhoneOutline,
  phone: Phone,
  arrowlarge: ArrowLarge,
  close: Close,
  plus: Plus,
  minus: Minus,
  logo: Logo,
  logoName: LogoName,
  bullet: Bullet,
};

export default icons;
