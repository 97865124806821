import {
  any,
  arrayOf,
  bool,
  number,
  oneOfType,
  shape,
  string,
} from "prop-types";

// Dato types
//

export const datoFile = {
  id: string,
  alt: string,
  fixed: any,
  fluid: any,
  size: number,
  width: number,
  height: number,
  format: string,
  url: string,
  title: string,
};

export const datoVideo = {
  id: string,
  source: shape({
    height: number,
    width: number,
    title: string,
    url: string,
    thumbnailUrl: string,
    provider: string,
  }),
};

// Custom types
//

// 'global' is reserved.
export const globalData = {
  id: string,
  street: string,
  zipcode: string,
  city: string,
  country: string,
  email: string,
  phone: string,
  facebook: string,
  twitter: string,
  instagram: string,
  linkedin: string,
  footerTitle: string,
  logoLight: shape(datoFile),
  logoDark: shape(datoFile),
  logoFooter: shape(datoFile),
  mapsLink: string,
  mapsText: string,
  beforePrivacyPolicy: string,
  privacyPolicyLinkText: string,
  afterPrivacyPolicy: string,
  privacyPolicyPath: string,
  newsletterButtonText: string,
};

export const author = {
  id: string,
  name: string,
};

export const updateType = {
  id: string,
  name: string,
};

export const callToAction = {
  id: string,
  text: string,
  url: string,
};

export const quote = {
  id: string,
  quote: string,
  author: string,
};

export const statementItem = {
  title: string,
  image: shape(datoFile),
};

export const updateItem = {
  id: string,
  date: string,
  author: shape(author),
  updateType: shape(updateType),
  thumbnail: shape(datoFile),
  title: string,
  intro: string,
  lead: string,
  content: arrayOf(
    oneOfType([
      shape(callToAction),
      shape(datoFile),
      shape(quote),
      shape(textBlockV1),
      shape(textBlockV3),
      shape(datoVideo),
    ])
  ),
  contactHighlight: shape(contactHighlight),
  slug: string,
};

export const homepage = {
  id: string,
  title: string,
  intro: string,
  servicesTitle: string,
  servicesDescription: string,
  services: arrayOf(shape(service)),
  principles: arrayOf(shape(imageCopyBlock)),
  cases: arrayOf(shape(caseItem)),
  updatesTitle: string,
  updates: arrayOf(shape(updateItem)),
};

export const customer = {
  id: string,
  name: string,
  slug: string,
  logo: shape(datoFile),
};

export const expertise = {
  title: string,
  text: string,
  image: string,
  id: string,
  methods: string,
  slug: string,
};

export const product = {
  id: string,
  title: string,
  text: string,
  image: string,
  methods: string,
  slug: string,
};

export const productBasic = {
  id: string,
  title: string,
  description: string,
};

export const service = {
  id: string,
  title: string,
  description: string,
};

export const person = {
  id: string,
  firstname: string,
  lastname: string,
  title: string,
  email: string,
  phone: string,
  avatar: shape(datoFile),
};

export const contactHighlight = {
  id: string,
  title: string,
  description: string,
  cta: string,
  person: shape(person),
};

export const textBlockV1 = {
  id: string,
  title: string,
  description: string,
  largeDescription: bool,
};

export const textBlockV2 = {
  id: string,
  title: string,
};

export const textBlockV3 = {
  id: string,
  title: string,
  intro: string,
  descriptionLeft: string,
  descriptionRight: string,
};

export const imageCarousel = {
  id: string,
  images: arrayOf(shape(datoFile)),
};

export const inlineCarousel = {
  id: string,
  title: string,
  description: string,
  images: arrayOf(shape(datoFile)),
};

export const imageBlockV1 = {
  id: string,
  title: string,
  description: string,
  actionText: string,
  actionUrl: string,
  image: shape(datoFile),
};

export const imageBlockV2 = {
  id: string,
  title: string,
  description: string,
  image: shape(datoFile),
};

// 'case' is reserved.
export const caseItem = {
  id: string,

  title: string,
  overviewTitle: string,
  description: string,
  tagline: string,

  thumbnail: shape(datoFile),
  slug: string,
  highlighted: bool,

  customer: shape(customer),
  services: arrayOf(shape(service)),
  products: arrayOf(shape(product)),
  awards: string,
  site: string,

  content: arrayOf(
    oneOfType([
      shape(datoFile),
      shape(datoVideo),
      shape(textBlockV1),
      shape(textBlockV2),
      shape(textBlockV3),
      shape(inlineCarousel),
      shape(imageCarousel),
      shape(imageBlockV1),
      shape(imageBlockV2),
      shape(quote),
    ])
  ),

  contactHighlight: shape(contactHighlight),
  relatedCases: arrayOf(shape(caseItem)),
};

export const vacancy = {
  id: string,
  jobTitle: string,
  externalLink: string,
  description: string,
  moreInfoLabel: string,
};

export const forty = {
  id: string,
  title: string,
  content: string,
  callToActionLabel: string,
  callToActionLink: string,
  logo: shape(datoFile),
};

export const imageCopyBlock = {
  id: string,
  title: string,
  image: shape(datoFile),
  content: string,
};

export const menuItem = {
  id: string,
  title: string,
  path: string,
};

export const menu = {
  id: string,
  items: arrayOf(shape(menuItem)),
  mobileBreakpoint: number,
};

export const about = {
  id: string,
  title: string,
  description: string,
  thumbnail: shape(datoFile),
  tagline: string,
  imageCarousel: arrayOf(shape(datoFile)),
  principles: arrayOf(shape(imageCopyBlock)),
  pageHighlightTitle: string,
  pageHighlightActionText: string,
  pageHighlightActionPath: string,
  forty: shape(forty),
};

export const partner = {
  id: string,
  name: string,
  logo: shape(datoFile),
};

export const partnerTheme = {
  id: string,
  title: string,
  highlight: string,
  partners: arrayOf(shape(partner)),
};

export const designProcess = {
  is: string,
  title: string,
  description: string,
  designTasks: string,
};

export const whatWeDo = {
  id: string,
  title: string,
  description: string,
  tagline: string,

  designProcessTitle: string,
  designProcessDescription: string,
  designProcessImage: shape(datoFile),
  designProcesses: arrayOf(shape(designProcess)),

  servicesTitle: string,
  servicesDescription: string,
  services: arrayOf(shape(service)),

  imageCarousel: arrayOf(shape(datoFile)),

  labTitle: string,
  labDescription: string,
  labTagline: string,
  labMethodsTitle: string,
  labMethods: string,

  contactHighlight: shape(contactHighlight),
  forty: shape(forty),
};

export const domainOverview = {
  id: string,
  title: string,
  description: string,
  descriptionHeading: string,
  tagline: string,
  heroImage: shape(datoFile),
  content: arrayOf(
    oneOfType([
      shape(datoFile),
      shape(datoVideo),
      shape(textBlockV1),
      shape(textBlockV2),
      shape(textBlockV3),
      shape(inlineCarousel),
      shape(imageCarousel),
      shape(imageBlockV1),
      shape(imageBlockV2),
      shape(quote),
    ])
  ),
  contactHighlight: shape(contactHighlight),
};

export const workWith = {
  id: string,
  title: string,
  tagline: string,
  description: string,
  vacanciesTitle: string,
  carousel: arrayOf(shape(datoFile)),
  vacancies: arrayOf(shape(vacancy)),
};

export const updatePage = {
  title: string,
  description: string,
};

export const privacyPage = {
  id: string,
  title: string,
  description: string,
  content: arrayOf(
    oneOfType([shape(textBlockV1), shape(datoFile), shape(textBlockV3)])
  ),
};

export const termsPage = {
  id: string,
  title: string,
  description: string,
  content: arrayOf(
    oneOfType([shape(textBlockV1), shape(datoFile), shape(textBlockV3)])
  ),
};

export const contact = {
  id: string,
  title: string,
  tagline: string,
  description: string,
  subDescription: string,
  vacancyActionText: string,
  vacancyActionPath: string,
  newsletterTitle: string,
  thumbnail: shape(datoFile),
};
