import { string } from "prop-types";
import React from "react";

function SolidArrow({ color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 43 26"
      className={`icon icon-arrow ${className}`}
      {...props}
    >
      <polygon
        points="30.1,25.5 28.7,24.1 39,13.8 0,13.8 0,11.8 39,11.8 28.7,1.5 30.1,0.1 42.1,12.1 41.4,12.8 42.1,13.5"
        fill={color ? color : "currentColor"}
      />
    </svg>
  );
}

SolidArrow.propTypes = {
  color: string,
  className: string,
};

SolidArrow.defaultProps = {
  color: null,
  className: "",
};

export default SolidArrow;
