import { string } from "prop-types";
import React from "react";

function MailOutline({ color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 36 36"
      className={`icon icon-mailoutline ${className}`}
      {...props}
    >
      <g>
        <path
          fill={color ? color : "currentColor"}
          d="M26.63,10.28H9.37a2,2,0,0,0-2,2v12a2,2,0,0,0,2,2H26.63a2,2,0,0,0,2-2v-12A2,2,0,0,0,26.63,10.28Zm0,2V14c-.95.79-2.45,2-5.6,4.45-.71.54-2.08,1.86-3,1.82S15.63,19,14.93,18.42c-3.16-2.49-4.65-3.66-5.56-4.45v-1.7Zm-17.26,12V16.55c.91.75,2.28,1.82,4.31,3.44.92.71,2.54,2.29,4.32,2.24S21.32,20.7,22.28,20c2-1.62,3.4-2.69,4.35-3.44v7.68Z"
        />
      </g>
    </svg>
  );
}

MailOutline.propTypes = {
  color: string,
  className: string,
};

MailOutline.defaultProps = {
  color: null,
  className: "",
};

export default MailOutline;
