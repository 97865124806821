import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../theme";
import Heading from "../Heading";
import Icon from "../Icon";
import ReplaceableLink from "../ReplaceableLink";
import { bodySmall } from "../Typography";

const svg =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 20"><polygon fill="#5801ff" points="3 5 2 5 2 0 1 0 1 5 0 5 0 15 1 15 1 20 2 20 2 15 3 15 3 5"/></svg>';

const svgImage = `data:image/svg+xml,${encodeURIComponent(svg)}`;

const StyledIcon = styled(Icon)`
  height: ${theme.fs("buttonIcon.height")};
  width: ${theme.fs("buttonIcon.width")};
  margin-top: ${theme.space("s")};
  color: ${theme.color("primary")};
  transition: ${theme.transition()};
`;

const StyledHeading = styled(Heading)`
  margin: ${theme.space("xs")} 0;
  color: ${theme.color("text.dark")};
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledReplaceableLink = styled(ReplaceableLink)`
  &:hover {
    color: ${theme.color("secondary")};
  }

  &:hover svg {
    transform: translateX(20px);
    color: ${theme.color("secondary")};
  }
`;

const Wrapper = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
  justify-content: space-between;
  white-space: nowrap;
  flex-wrap: wrap;
`;

export const ListItem = styled.li`
  ${bodySmall};
  line-height: ${theme.lh("ctaSmall")};

  position: relative;
  list-style: none;

  ${({ bulleted }) =>
    bulleted &&
    css`
      &:before {
        content: "";
        display: inline-block;
        height: 1em;
        width: 1em;
        background-image: url(${svgImage});
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 0.5em;
        margin-top: 0.2em;
        position: relative;
        top: 4px;
        min-height: 20px;
      }
    `};
`;

function List({
  items,
  vertical,
  bulleted,
  heading,
  link,
  linkTitle,
  ...props
}) {
  return (
    <ListWrapper>
      {heading && <StyledHeading size={4}>{heading}</StyledHeading>}
      <Wrapper bulleted={bulleted} vertical={vertical} {...props}>
        {items.map((item) => (
          <ListItem bulleted={bulleted} key={item.id || item} {...item.props}>
            {item.render || item.trim()}
          </ListItem>
        ))}
      </Wrapper>

      {link && (
        <StyledReplaceableLink as={link}>
          {linkTitle && <span>{linkTitle}</span>}
          <StyledIcon type={"arrow"} color={theme.color("secondary")} />
        </StyledReplaceableLink>
      )}
    </ListWrapper>
  );
}

export const listItem = PropTypes.shape({
  id: PropTypes.string.isRequired,
  render: PropTypes.node.isRequired,
  props: PropTypes.any,
});

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.oneOfType([listItem, PropTypes.string])),
  active: listItem,
  vertical: PropTypes.bool,
  bulleted: PropTypes.bool,
  heading: PropTypes.string,
  link: PropTypes.elementType,
  linkTitle: PropTypes.string,
};

List.defaultProps = {
  vertical: false,
  bulleted: false,
};

export default List;
