import theme from "../../theme";

const mq = (n) => {
  const bp = theme.get("breakpoints");
  const bpArray = Object.keys(bp).map((key) => [key, bp[key]]);

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media screen and (min-width: ${size})`];
    return acc;
  }, []);

  return result;
};

export const customMq = (size, minMax = "min") =>
  `@media screen and (${minMax}-width: ${size}px)`;

export default mq;
