import styled from "@emotion/styled";
import { Elastic, gsap, Power4 } from "gsap";
import PropTypes from "prop-types";
import React, { useEffect, useLayoutEffect, useRef } from "react";

import useGsapSelector from "../../hooks/useGsapSelector";
import theme from "../../theme";
import { pillButton } from "../Typography";

const appearances = {
  default: {
    border: theme.color("primary.base"),
    textColor: theme.color("primary.base"),
    background: "transparent",
    hover: {
      border: theme.color("primary.base"),
      textColor: theme.color("text.light"),
      background: theme.color("primary.base"),
    },
  },
  light: {
    border: theme.color("text.light"),
    textColor: theme.color("text.light"),
    background: "transparent",
    hover: {
      border: theme.color("primary.base"),
      textColor: theme.color("text.light"),
      background: theme.color("primary.base"),
    },
  },
  lightInverted: {
    border: theme.color("text.light"),
    textColor: theme.color("primary.base"),
    background: theme.color("text.light"),
    hover: {
      border: theme.color("text.light"),
      textColor: theme.color("text.light"),
      background: "transparent",
    },
  },
};

const Wrapper = styled.div`
  all: unset;
  display: flex;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  text-decoration: none;
  padding: 0 32px 0 ${({ emptyLabel }) => (emptyLabel ? "22px" : "32px")};
  gap: 16px;
  background-color: ${({ appearance }) => appearances[appearance].background};
  border: 2px solid ${({ appearance }) => appearances[appearance].border};
  border-radius: 64px;
`;

const ButtonTextScroller = styled.div`
  position: relative;
  height: ${30 + 2 * 16}px;
  overflow: hidden;
`;

const ButtonTextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  color: ${({ appearance }) => appearances[appearance].textColor};
`;

const ButtonText = styled.span`
  ${pillButton};
  padding: 16px 0;
  color: inherit;
`;

const SvgWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
`;

const AnimatingSvg = styled.svg`
  position: absolute;
  right: 0;
`;

const PillButton = ({ label, appearance, animation, ...props }) => {
  const containerRef = useRef();
  const buttonRef = useRef();
  const svgWrapperRef = useRef();
  const textWrapperRef = useRef();
  const [q, svgElement] = useGsapSelector();

  const timeline = useRef(gsap.timeline());
  const tl = timeline.current;

  function playHover() {
    tl.reversed(!tl.reversed());
  }

  useLayoutEffect(() => {
    if (label !== null) {
      tl.to(
        textWrapperRef.current,
        {
          duration: 1,
          y: -((textWrapperRef.current.clientHeight / 3) * 2),
          color: appearances[appearance].hover.textColor,
          ease: Elastic.easeInOut.config(1, 1),
        },
        0
      );
    }
    tl.to(
      buttonRef.current,
      {
        duration: 1,
        backgroundColor: appearances[appearance].hover.background,
        borderColor: appearances[appearance].hover.border,
        ease: Power4.easeInOut,
      },
      0
    );
    if (animation !== "no-resize") {
      tl.to(
        svgWrapperRef.current,
        {
          duration: 1,
          width: 120,
          ease: Elastic.easeInOut.config(2, 1),
        },
        0
      );
      tl.to(
        q("line"),
        {
          duration: 1,
          attr: { x2: 8 },
          ease: Elastic.easeInOut.config(2, 1),
        },
        0
      );
      tl.to(
        q("polyline, line"),
        {
          duration: 1,
          attr: { stroke: appearances[appearance].hover.textColor },
          ease: Elastic.easeInOut.config(2, 1),
        },
        0
      );
    }
    tl.reversed(true);
    return () => {
      tl.kill();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    const containerElement = containerRef && containerRef.current;
    if (!containerElement || animation === "disabled") {
      return;
    }
    containerElement.addEventListener("mouseenter", playHover);
    containerElement.addEventListener("mouseleave", playHover);
    return () => {
      containerElement.removeEventListener("mouseenter", playHover);
      containerElement.removeEventListener("mouseleave", playHover);
    };
  });

  return (
    <Wrapper ref={containerRef} {...props}>
      <ButtonWrapper
        ref={buttonRef}
        appearance={appearance}
        emptyLabel={label ? false : true}
      >
        <ButtonTextScroller>
          {label && (
            <ButtonTextWrapper ref={textWrapperRef} appearance={appearance}>
              <ButtonText>{label}</ButtonText>
              <ButtonText>{label}</ButtonText>
              <ButtonText>{label}</ButtonText>
            </ButtonTextWrapper>
          )}
        </ButtonTextScroller>

        <SvgWrapper ref={svgWrapperRef}>
          <AnimatingSvg
            ref={svgElement}
            width="120"
            height="60"
            viewBox="0 0 120 60"
          >
            <line
              x1="118"
              y1="30"
              x2="78"
              y2="30"
              strokeWidth="2"
              stroke={appearances[appearance].textColor}
            />
            <polyline
              points="108,20 118,30 108,40"
              fill="none"
              stroke={appearances[appearance].textColor}
              strokeWidth="2"
            />
          </AnimatingSvg>
        </SvgWrapper>
      </ButtonWrapper>
    </Wrapper>
  );
};

PillButton.propTypes = {
  label: PropTypes.string,
  appearance: PropTypes.oneOf(["default", "light", "lightInverted"]),
  animation: PropTypes.oneOf(["disabled", "no-resize"]),
};

PillButton.defaultProps = {
  appearance: "default",
  label: "lees meer",
};

export default PillButton;
