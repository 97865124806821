import { pipe, replace } from "ramda";
const removeWWW = replace("www.", "");
const removeProtocol = replace(/^(http|https):\/\//, "");
const cleanUrl = pipe(removeWWW, removeProtocol);

export const stripLink = (hostname = "", href = "") => {
  if (hostname === "") return href;

  const _hostname = cleanUrl(hostname);
  const withoutProtocol = cleanUrl(href);
  const regex = new RegExp(`^(?:www\\.)?${_hostname}`);

  return regex.test(withoutProtocol)
    ? replace(regex, "", withoutProtocol)
    : href;
};

export const shouldTreatAsInternal = (hostname = "", href = "") => {
  // When href starts with / we can assume it's internal
  if (/^\/(?!\/)/.test(href)) return true;

  // Build dynamic regex based on hostname
  const regex = new RegExp(`^(?:www\\.)?${cleanUrl(hostname)}/.+`);

  // Check if href matches the regex pattern
  const matches = regex.test(cleanUrl(href));

  return matches;
};
