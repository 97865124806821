import styled from "@emotion/styled";
import GatsbyLink from "gatsby-link";
import PropTypes from "prop-types";
import React from "react";

import useSkipLinks from "../../hooks/useSkipLinks";
import theme from "../../theme";

const SkipToContentWrapper = styled.div`
  position: absolute;
  background: ${theme.color("primary")};
  left: 25%;
  top: 0;
  padding: ${theme.space("xs")};
  transform: translateY(-100%);
  transition: transform 0.35s cubic-bezier(0.72, 0, 0.28, 1);
  z-index: 1000;
  &:focus-within {
    transform: translateY(0%);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: ${theme.space("xs")};
    li {
      a {
        color: white;
      }
    }
  }
`;

const SkipToContentLink = () => {
  const { skipLinks } = useSkipLinks();

  return (
    <SkipToContentWrapper>
      <ul>
        <li>
          <GatsbyLink to="#main-content">
            Ga direct naar pagina-inhoud
          </GatsbyLink>
        </li>
        {skipLinks.map((link) => (
          <li key={link.title}>
            <GatsbyLink to={`#${link.id}`}>Ga naar {link.title}</GatsbyLink>
          </li>
        ))}
      </ul>
    </SkipToContentWrapper>
  );
};

SkipToContentLink.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  scrollOffset: PropTypes.number,
  targetRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};
export { SkipToContentLink };
