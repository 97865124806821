import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { prop } from "ramda";
import React, { useState } from "react";

import theme from "../../theme";
import validateEmail from "../../utils/formValidation";
import submitRequest from "../../utils/laposta";
import Checkbox from "../Checkbox";
import Form from "../Form";
import PillButton from "../PillButton";
import PillInput from "../PillInput";

const PrivacyLink = styled.a`
  text-decoration: none;
  color: ${({ appearance }) =>
    appearance == "light"
      ? theme.color("text.light")
      : theme.color("dark.light")};
  border-bottom: ${({ appearance }) =>
    appearance == "light"
      ? `1px solid ${theme.color("text.light")}`
      : `1px solid ${theme.color("dark.light")}`};

  &:hover,
  &:focus {
    border-color: ${({ appearance }) =>
      appearance == "light"
        ? theme.color("dark.light")
        : theme.color("primary.base")};
    color: ${({ appearance }) =>
      appearance == "light"
        ? theme.color("dark.light")
        : theme.color("primary.base")};
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing("xxs")};
`;

function NewsletterForm({ status, appearance, formLabels }) {
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [notification, setNotification] = useState({});
  const submit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      setNotification({
        message: "Dit is een ongeldig emailadres.",
        color: getNotificationColor("error"),
        status: "error",
      });
      return;
    }
    if (!checked) {
      setNotification({
        message: "We hebben nog even een vinkje nodig bij het privacybeleid.",
        color: getNotificationColor("error"),
        status: "error",
      });
      return;
    }

    // Send the email address to LaPosta
    const result = await submitRequest({ email });
    setNotification({
      message: result.message,
      color: getNotificationColor(result.status),
      status: result.status,
    });
  };

  const getNotificationColor = (status) => {
    if (status === "success") {
      return theme.color("primary.light");
    }
    return theme.color("secondary");
  };

  return (
    <Form onSubmit={submit}>
      <InputWrapper>
        <PillInput
          appearance={appearance == "light" ? "light" : "normal"}
          placeholder="Vul uw e-mailadres in"
          value={email}
          onChange={(_, e) => setEmail(e.value)}
          errorColor={notification.color}
          notification={notification.message}
        />
        <PillButton
          appearance={appearance == "light" ? "lightInverted" : "default"}
          as="button"
          label={null}
          onClick={submit}
          disabled={status === "sending" || status === "success"}
        />
      </InputWrapper>
      <div>
        <Checkbox
          id="policy"
          appearance={appearance == "light" ? "light" : "default"}
          checked={checked}
          label={
            <span>
              <span>{prop("beforePrivacyPolicy", formLabels)} </span>
              <PrivacyLink
                appearance={appearance == "light" ? "light" : "normal"}
                href={prop("privacyPolicyPath", formLabels)}
              >
                <span>{prop("privacyPolicyLinkText", formLabels)}</span>
              </PrivacyLink>
              <span>{prop("afterPrivacyPolicy", formLabels)}</span>
            </span>
          }
          onChange={() => setChecked(!checked)}
        />
      </div>
    </Form>
  );
}

NewsletterForm.propTypes = {
  status: PropTypes.string,
  formLabels: PropTypes.object,
  appearance: PropTypes.oneOf(["light", "dark"]),
};

export default NewsletterForm;
