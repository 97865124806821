import { oneOf } from "prop-types";
import React from "react";

import icons from "./icons";

function Icon({ type, ...props }) {
  const IconComp = icons[type];
  return <IconComp {...props} />;
}

Icon.propTypes = {
  type: oneOf(Object.keys(icons)).isRequired,
};

export default Icon;
