import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import { menu } from "../../types";
import createLink from "../../utils/createLink";
import menuItems from "../../utils/menuItems";
import HeaderComponent from "../HeaderComponent";
import LogoName from "../Icon/LogoName";

const propTypes = {
  appearance: PropTypes.oneOf(["default", "light"]),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    data: PropTypes.shape(menu),
  }),
};

export const PureHeader = ({ appearance = "default", location, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      data: dato {
        menu {
          items {
            label: title
            path
          }
          mobileBreakpoint
        }
      }
    }
  `)?.data?.menu;

  let paths = [{ label: "Home", path: "/", order: 0 }];

  data?.items.map(({ label, path }, i) => {
    paths.push({
      label,
      path,
      order: i + 1,
    });
  });

  const newItems = menuItems(paths, location);
  return (
    <HeaderComponent
      homeAs={createLink("/")}
      logo={<LogoName />}
      navigationItems={newItems}
      activeItem={newItems.find(({ active }) => active)}
      appearance={appearance}
      breakpoint={data?.mobileBreakpoint}
      {...props}
    />
  );
};

PureHeader.propTypes = propTypes;

const Header = ({ appearance = "default", location, ...props }) => {
  return <PureHeader appearance={appearance} location={location} {...props} />;
};

Header.propTypes = propTypes;

export default Header;
