import { string } from "prop-types";
import React from "react";

function Instagram({ color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 36 36"
      className={`icon icon-arrow ${className}`}
      {...props}
    >
      <g transform="translate(4.5, 4.5)">
        <path
          fill={color ? color : "currentColor"}
          d="M20.2,20.8H6.8c-0.3,0-0.6-0.3-0.6-0.6v-8.4h2.2C8.1,12.2,8,13,8,13.5c0,3,2.5,5.5,5.5,5.5
						c3,0,5.5-2.5,5.5-5.5c0-0.5-0.1-1.3-0.4-1.7h2.2v8.4C20.8,20.6,20.6,20.8,20.2,20.8 M13.5,10.1c1.9,0,3.5,1.5,3.5,3.5
						c0,1.9-1.5,3.5-3.5,3.5c-1.9,0-3.5-1.5-3.5-3.5C10.1,11.6,11.6,10.1,13.5,10.1 M18,6.8h1.7c0.3,0,0.6,0.3,0.6,0.6V9
						c0,0.3-0.3,0.6-0.6,0.6H18c-0.3,0-0.6-0.3-0.6-0.6V7.3C17.4,7,17.7,6.8,18,6.8 M20.4,4.5H6.6c-1.2,0-2.1,1-2.1,2.1v13.7
						c0,1.2,1,2.1,2.1,2.1h13.7c1.2,0,2.1-1,2.1-2.1V6.6C22.5,5.5,21.5,4.5,20.4,4.5"
        />
      </g>
    </svg>
  );
}

Instagram.propTypes = {
  color: string,
  className: string,
};

Instagram.defaultProps = {
  color: null,
  className: "",
};

export default Instagram;
