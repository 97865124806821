export default {
  dark: {
    base: "#32323C",
    light: "#8D8D8D",
    lighter: "#ECEFF3",
    lightest: "#FAFAFA",
  },

  primary: {
    base: "#5801FF",
    light: "#D3C3F5",
  },

  secondary: {
    base: "#FF554E",
    light: "#FFCCC9",
  },

  blue: {
    base: "#A5CEEF",
    light: "#EDF5FC",
  },

  gray: {
    base: "#D6D6D6",
  },

  text: {
    dark: "#1F1F1F",
    base: "#32323C",
    light: "#FFFFFF",
  },

  background: {
    dark: "#32323C",
    base: "#ffffff",
    light: "#EDF5FC",
    lighter: "#FAFAFA",
  },

  link: {
    base: "#fe7c08",
    over: "#fea04c",
  },
  socialmedia: {
    base: "#5801FF",
    light: "#FFFFFF",
    dark: "#000000",
  },

  marquee: {
    base: "#5801FF",
    text: "#ff554e",
    alt: "#ECEFF3",
  },

  modal: {
    overlay: "#5801FF",
    closeButton: "#FFFFFF",
    closeButtonHover: "rgba(255,255,255, 0.5)",
  },
};
