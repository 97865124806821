const submitRequest = async ({ email }) => {
  let myHeaders = new Headers();
  myHeaders.append("Accept-Charset", "utf-8");
  myHeaders.append(
    "Authorization",
    `Basic ${process.env.GATSBY_LAPOSTA_API_TOKEN}`
  );

  let formdata = new FormData();
  formdata.append("list_id", process.env.GATSBY_LAPOSTA_LIST_ID);
  formdata.append("ip", "198.51.100.0");
  formdata.append("email", email);
  formdata.append("source_url", "https://www.greenberry.nl");
  // formdata.append("ignore_doubleoptin", "false");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  let result;
  try {
    const response = await fetch(
      "https://api.laposta.nl/v2/member",
      requestOptions
    );

    result = await response.json();
  } catch (error) {
    return {
      status: "error",
      message: "Er is helaas iets misgegaan, probeer het nog eens.",
    };
  }

  if (result.error !== undefined) {
    return transformErrorMessage(result);
  }
  return transformSuccessfulMessage(result);
};

const transformSuccessfulMessage = (result) => {
  return {
    status: "success",
    message: "Bedankt voor je aanmelding!",
    raw: result,
  };
};
const transformErrorMessage = (result) => {
  return {
    status: "error",
    message: result.error.message,
    raw: result.error,
  };
};

export default submitRequest;
