import styled from "@emotion/styled";
import GatsbyLink from "gatsby-link";
import { node } from "prop-types";
import { object } from "prop-types";
import { array } from "prop-types";
import { equals } from "ramda";
import React from "react";

import useModal from "../../hooks/useModal";
import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import ContentWrapper from "../ContentWrapper";
import { ListItem } from "../List";
import Modal from "../Modal";
import PillButton from "../PillButton";
import { caption, footerNavigation } from "../Typography";

const FooterWrapper = styled.footer`
  position: relative;
  padding: ${theme.spacing("s")} 0;

  background-color: ${theme.color("text.dark")};
`;

const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing("s")};
  ${mq("4")} {
    gap: ${theme.spacing("l")};
  }
`;

const TitleWrapper = styled.div`
  color: ${theme.color("text.light")};
  ${mq("2")} {
    max-width: ${theme.spacing("grid[3]")};
  }
`;

const NavigationSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing("s")};
  width: 100%;
  align-items: flex-start;
  ${mq("4")} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const NewsletterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing("s")};
  align-items: flex-start;
  color: ${theme.color("text.light")};
  ${mq("2")} {
    flex-direction: row;
    align-items: center;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing("xs")};
  ${mq("3")} {
    flex-direction: row;
    gap: ${theme.spacing("l")};
  }
`;

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing("xs")};
`;

const PrivacyLinks = styled(Navigation)`
  gap: ${theme.spacing("s")};
  a {
    cursor: pointer;
    color: ${theme.color("text.light")};
    text-decoration: none;
    &:hover {
      color: ${theme.color("dark.light")};
      text-decoration: underline;
    }
  }
`;

const TaglineWrapper = styled.div`
  ${footerNavigation};
`;

const Divider = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.25);
`;

const MobileDivider = styled(Divider)`
  display: block;
  ${mq("4")} {
    display: none;
  }
`;

const StyledListItem = styled(ListItem)`
  ${footerNavigation};
  color: ${theme.color("text.light")};

  a {
    cursor: pointer;
    color: ${theme.color("text.light")};
    text-decoration: none;
    &:hover {
      color: ${theme.color("dark.light")};
      text-decoration: underline;
    }
  }

  text-decoration: ${({ isActive }) => (isActive ? "underline" : "none")};
`;

const Copyright = styled.div`
  ${caption};
  color: ${theme.color("text.light")};
`;

const CloseButton = styled("button")`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: ${theme.spacing("s")};
  right: ${theme.spacing("s")};
  height: 80px;
  width: 80px;
  z-index: 100;
  background: transparent;
  border: 1px solid ${theme.color("modal.closeButton")};
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: 2rem;
  transform: rotate(45deg);
  transition: ${theme.get("transition.default.transition")};
  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: ${theme.color("modal.closeButton")};
    transition: ${theme.get("transition.default.transition")};
  }

  &:before {
    width: 2px;
    height: 40%;
  }

  &:after {
    height: 2px;
    width: 40%;
  }

  &:hover {
    border: 1px solid ${theme.color("modal.closeButtonHover")};
    transform: rotate(135deg);
    &:before,
    &:after {
      background: ${theme.color("modal.closeButtonHover")};
    }
  }
  ${mq("3")} {
    top: ${theme.spacing("xl")};
    right: ${theme.spacing("xl")};
  }
`;

const FooterComponent = ({
  title,
  social,
  newsletter,
  navigation,
  activeNavigationItem,
  ...props
}) => {
  const { isShowing, toggle } = useModal();

  return (
    <FooterWrapper {...props}>
      <StyledContentWrapper columns={12}>
        {title && <TitleWrapper>{title}</TitleWrapper>}
        <Divider />
        <NavigationSection>
          <Navigation>
            {navigation &&
              navigation.map((item) => (
                <StyledListItem
                  key={item.id}
                  isActive={equals(item, activeNavigationItem)}
                >
                  {item.render}
                </StyledListItem>
              ))}
          </Navigation>
          <MobileDivider />
          <NewsletterSection>
            <TaglineWrapper>{"Blijf up to date:"}</TaglineWrapper>
            <PillButton
              appearance="light"
              label={"Nieuwsbrief"}
              onClick={toggle}
              animation="no-resize"
            />
          </NewsletterSection>
        </NavigationSection>
        <NavigationSection>
          <PrivacyLinks>
            <GatsbyLink to="/algemene-voorwaarden/">
              Algemene Voorwaarden
            </GatsbyLink>
            <GatsbyLink to="/privacy">Privacy</GatsbyLink>
            <GatsbyLink to="/iso-27001">ISO-27001</GatsbyLink>
          </PrivacyLinks>
          {social && <SocialWrapper>{social}</SocialWrapper>}
        </NavigationSection>
        <Divider />
        <Copyright>© {new Date().getFullYear()}</Copyright>
      </StyledContentWrapper>
      <Modal
        isShowing={isShowing}
        hide={toggle}
        title={"Blijf op de hoogte van het laatste nieuws"}
        closeButton={<CloseButton onClick={toggle} />}
      >
        {newsletter}
      </Modal>
    </FooterWrapper>
  );
};

FooterComponent.propTypes = {
  title: node.isRequired,
  social: node.isRequired,
  newsletter: node.isRequired,
  navigation: array.isRequired,
  activeNavigationItem: object,
};

FooterComponent.defaultProps = {};

export default FooterComponent;
