import styled from "@emotion/styled";
import { elementType, node, string } from "prop-types";
import React from "react";

const Component = ({ url, children, as: As, ...props }) =>
  As ? (
    <As {...props}>{children}</As>
  ) : (
    <a href={url} {...props}>
      {children}
    </a>
  );

Component.propTypes = {
  url: string,
  as: elementType,
  children: node.isRequired,
};

const ReplaceableLink = styled(Component)`
  text-decoration: none;
`;

export default ReplaceableLink;
