import { Link } from "gatsby";
import { map } from "ramda";
import React from "react";

const menuItems = (items, location) =>
  map(
    ({ path, label }) => ({
      id: path,
      render: <Link to={path}>{label}</Link>,
      active:
        location &&
        getFirstLevelPathname(location?.pathname) === removeSlashes(path),
    }),
    items.sort((a, b) => a.order - b.order)
  );

export default menuItems;

/**
 * Gets the current browsers first-level pathname.
 * @param e
 * @returns {null|*}
 */
function getFirstLevelPathname(e) {
  if (e) {
    const [, path] = e.split("/");
    return path;
  }

  return null;
}

/**
 * Removes slashes '/' from a string.
 * @param {string} value
 */
function removeSlashes(value) {
  if (!value) {
    return "";
  }

  return value.replace(/\//g, "");
}
