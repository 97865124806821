import { css } from "@emotion/core";
import styled from "@emotion/styled";
import useLabelPrefix from "@greenberry/use-label-prefix";
import PropTypes from "prop-types";
import React, { useState } from "react";

import theme from "../../theme";
import { bodySmall } from "../Typography";

const StyledInput = styled.input`
  ${bodySmall};
  border: 2px solid
    ${({ appearance, baseColor }) =>
      appearance === "normal" ? baseColor : "transparent"};
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 18px 32px;
  color: ${({ notification, baseColor, errorColor }) =>
    notification ? errorColor : baseColor};
  transition: ${theme.get("transition.default.transition")};
  border-radius: 64px;
  &:focus {
    outline: none;
    border: 2px solid
      ${({ notification, baseColor, errorColor }) =>
        notification ? errorColor : baseColor};
  }
  ${({ notification, errorColor }) =>
    notification && `border-color: ${errorColor}`};
`;

const StyledPlaceholder = styled.label`
  position: absolute;
  left: 32px;
  pointer-events: none;
  display: flex;
  align-items: center;
  font-family: ${theme.get("type.fontFamily.secondary")};
  font-size: ${({ filled }) => (filled ? "13px" : theme.fs("bodySmall"))};
  color: ${({ notification, baseColor, errorColor }) =>
    notification ? errorColor : baseColor};
  transition: ${theme.get("transition.default.transition")};
  transform: ${({ filled }) =>
    filled ? "translateY(-21px)" : "translateY(0)"};
`;

const StyledNotification = styled.span`
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: absolute;
  bottom: 0;
  left: 32px;
  pointer-events: none;
  display: block;
  font-family: ${theme.get("type.fontFamily.secondary")};
  color: ${({ errorColor }) => errorColor} !important;
  transform: translateY(16px);
  font-size: 0.8rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const normalWrapper = css`
  border: 2px solid ${theme.color("dark.base")};
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 64px;
  /* ${({ appearance }) => appearance === "normal" && normalWrapper}; */
`;

const PillInput = ({
  onClick,
  type,
  ariaLabel,
  placeholder,
  notification,
  value,
  appearance,
  errorColor,
  onChange,
  ...rest
}) => {
  const [filled, setFilled] = useState(value !== "");
  const prefixedLabel = useLabelPrefix(ariaLabel ? ariaLabel : placeholder);

  const handleOnBlur = (event) => {
    event.target.value !== "" ? setFilled(true) : setFilled(false);
  };

  const baseColor =
    appearance === "normal"
      ? theme.color("dark.base")
      : theme.color("text.light");

  return (
    <Wrapper>
      <InputWrapper appearance={appearance}>
        <StyledInput
          {...rest}
          onClick={onClick}
          onBlur={handleOnBlur}
          onFocus={() => {
            setFilled(true);
          }}
          aria-label={ariaLabel ? ariaLabel : placeholder}
          id={prefixedLabel}
          type={type}
          defaultValue={value}
          notification={notification}
          baseColor={baseColor}
          errorColor={errorColor}
          onChange={onChange && ((e) => onChange(e, e.target))}
          appearance={appearance}
        />
        <StyledPlaceholder
          htmlFor={prefixedLabel}
          baseColor={baseColor}
          errorColor={errorColor}
          filled={filled}
          notification={notification}
        >
          {placeholder}
        </StyledPlaceholder>
      </InputWrapper>

      {notification && (
        <StyledNotification baseColor={baseColor} errorColor={errorColor}>
          {notification}
        </StyledNotification>
      )}
    </Wrapper>
  );
};

PillInput.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  ariaLabel: PropTypes.string,
  placeholder: PropTypes.string,
  backgroundColor: PropTypes.string,
  value: PropTypes.string,
  notification: PropTypes.string,
  appearance: PropTypes.oneOf(["normal", "light"]),
  errorColor: PropTypes.string,
  onChange: PropTypes.func,
};

PillInput.defaultProps = {
  type: "text",
  value: "",
  backgroundColor: theme.color("text.light"),
  appearance: "normal",
  errorColor: theme.color("primary"),
};

export default PillInput;
