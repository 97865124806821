import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import { prop } from "ramda";
import React from "react";

import theme from "../../theme";
import { globalData } from "../../types";
import menuItems from "../../utils/menuItems";
import FooterComponent from "../FooterComponent";
import Heading from "../Heading";
import NewsletterForm from "../NewsletterForm";
import SocialMedia from "../SocialMedia";
import { footerNavigation } from "../Typography";

const MailchimpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 520px;
`;

const TaglineWrapper = styled.div`
  ${footerNavigation};
  color: ${theme.color("text.light")};
  margin-bottom: ${theme.space("m")};
`;

export const PureFooter = ({ data, location }) => {
  const globalData = data.global;
  const footerData = data.footer;

  let footerMenu = [];
  footerData?.items.map(({ label, path }, i) => {
    footerMenu.push({
      label,
      path,
      order: i,
    });
  });

  const navigationItems = menuItems(footerMenu, location);
  const activeNavigationItem = navigationItems.find(({ active }) => active);

  const facebookLink = prop("facebook", globalData);
  const instagramLink = prop("instagram", globalData);
  const twitterLink = prop("twitter", globalData);
  const linkedinLink = prop("linkedin", globalData);

  return (
    <FooterComponent
      title={
        <Heading size={3} as="h2">
          {prop("footerTitle", globalData)}
        </Heading>
      }
      social={
        <>
          {facebookLink && (
            <SocialMedia simple type="facebook" link={facebookLink} />
          )}
          {instagramLink && (
            <SocialMedia simple type="instagram" link={instagramLink} />
          )}
          {twitterLink && (
            <SocialMedia simple type="twitter" link={twitterLink} />
          )}
          {linkedinLink && (
            <SocialMedia simple type="linkedin" link={linkedinLink} />
          )}
        </>
      }
      navigation={navigationItems}
      activeNavigationItem={activeNavigationItem}
      newsletter={
        <MailchimpWrapper>
          <TaglineWrapper>{prop("footerTagline", globalData)}</TaglineWrapper>
          <NewsletterForm
            backgroundColor={theme.color("text.dark")}
            appearance="light"
            formLabels={globalData}
          />
        </MailchimpWrapper>
      }
      footerTagline={prop("footerTagline", globalData)}
    />
  );
};

PureFooter.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape(globalData),
  }),
  status: PropTypes.string,
  message: PropTypes.string,
  onValidated: PropTypes.func,
};

const Footer = ({ location }) => {
  const { data } = useStaticQuery(
    graphql`
      query FooterQuery {
        data: dato {
          global {
            street
            zipcode
            city
            country
            phone
            email
            facebook
            twitter
            instagram
            linkedin
            mapsLink
            mapsText
            footerTitle
            footerTagline
            newsletterButtonText
            beforePrivacyPolicy
            afterPrivacyPolicy
            privacyPolicyLinkText
            privacyPolicyPath
          }
          footer {
            items {
              path
              label: title
            }
            services {
              slug
              label: title
            }
          }
        }
      }
    `
  );

  return <PureFooter data={data} location={location} />;
};

Footer.propTypes = PureFooter.propTypes;

export default Footer;
