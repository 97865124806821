import { string } from "prop-types";
import React from "react";

function Close({ color, className, ...props }) {
  return (
    <svg
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon-close ${className}`}
      {...props}
    >
      <path
        d="m2.9534728 16.9292304c-3.90524291-3.9052429-3.94248789-10.19964769-.08318899-14.05894659s10.15370369-3.82205392 14.05894659.08318899 3.9424879 10.1996477.083189 14.0589466-10.15370369 3.8220539-14.0589466-.083189zm6.90468979-6.2391776 3.20277781 3.2027778.6655122-.6655122-3.2027778-3.2027778 3.2027778-3.20277779-.6655122-.66551225-3.20277781 3.2027778-3.20277777-3.20277777-.66551224.66551225 3.20277777 3.20277776-3.20277777 3.2027778.66551224.6655122z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

Close.propTypes = {
  color: string,
  className: string,
};

Close.defaultProps = {
  color: "currentColor",
  className: "",
};

export default Close;
