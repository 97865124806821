import { string } from "prop-types";
import React from "react";

function Mail({ color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 36 36"
      className={`icon icon-arrow ${className}`}
      {...props}
    >
      <g>
        <path
          fill={color ? color : "currentColor"}
          d="M27.6,25.3H8.7l7.1-5.1c1.2,0.7,3.5,0.7,4.7,0L27.6,25.3z M16.7,19.3c0.8,0.5,1.7,0.5,2.5,0l10-6.9v-1
	c0-0.4-0.4-0.8-0.8-0.8H7.6c-0.4,0-0.8,0.4-0.8,0.8v1L16.7,19.3z M6.8,14.1v11.2l7.9-5.9L6.8,14.1z M21.4,19.4l7.9,5.9V14.1
	L21.4,19.4z"
        />
      </g>
    </svg>
  );
}

Mail.propTypes = {
  color: string,
  className: string,
};

Mail.defaultProps = {
  color: null,
  className: "",
};

export default Mail;
