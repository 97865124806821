import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes, { node } from "prop-types";
import React from "react";

import theme from "../../theme";
import { bodySmall } from "../Typography";
const svg =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 20"><polygon fill="#5801ff" points="3 5 2 5 2 0 1 0 1 5 0 5 0 15 1 15 1 20 2 20 2 15 3 15 3 5"/></svg>';

const svgImage = `data:image/svg+xml,${encodeURIComponent(svg)}`;

const listStyling = css`
  ul {
    margin-left: 0;
    white-space: normal;
    list-style-type: none;
    padding: 0;
    li {
      line-height: ${theme.lh("bodySmall")};
      position: relative;
      margin-bottom: 0.75rem;
      padding-left: 1rem;
      &:before {
        content: "";
        display: inline-block;
        height: 1em;
        width: 1em;
        background-image: url(${svgImage});
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 0.2em;
        position: absolute;
        top: 1px;
        left: 0;
      }
    }
  }
`;

const baseStyling = css`
  ${bodySmall};
  color: ${theme.color("text.base")};
  white-space: pre-line;
  ul,
  ol {
    margin-left: 0;
    padding-left: ${theme.space("s")};
    white-space: normal;
    li {
    }
  }
`;

const Paragraph = styled.p`
  ${baseStyling};
  ${listStyling};
`;

const DomWrapper = styled.div`
  ${baseStyling};
  p {
    &:first-of-type {
      margin: 0.5rem 0 1rem 0;
    }
    margin: 0 0 1rem 0;
  }
  ${listStyling};
`;

const Text = React.forwardRef(({ children, html = false, ...props }, ref) =>
  html ? (
    <DomWrapper
      as={"div"}
      {...props}
      dangerouslySetInnerHTML={{ __html: children }}
      ref={ref}
    />
  ) : (
    <Paragraph ref={ref} {...props}>
      {children}
    </Paragraph>
  )
);

Text.displayName = "Text";

Text.propTypes = {
  children: node.isRequired,
  html: PropTypes.bool,
};

export default Text;
