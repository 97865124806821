import styled from "@emotion/styled";
import { func, node, shape, string } from "prop-types";
import React from "react";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import Button from "../Button";
import ContentWrapper from "../ContentWrapper";

const Wrapper = styled.div`
  width: 100%;
  background: ${theme.color("background.dark")};
  position: fixed;
  bottom: 0;
  z-index: ${theme.get("zIndex.high")};
`;

const Content = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mq("3")} {
    flex-direction: row;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 2rem;
  align-items: center;
  ${mq("3")} {
    margin-top: 0;
    margin-left: auto;
    flex-direction: row;
  }
`;

const StyledButton = styled(Button)`
  margin: 0.5rem 0;
  color: white;
  ${mq("3")} {
    margin: 0 0 0 2rem;
  }
`;

const Message = styled.div`
  margin-bottom: -1rem;
  p,
  a {
    color: white;
  }
  ${mq("3")} {
    max-width: 50%;
  }
`;

const CookieWarning = ({ message, accept, reject }) => (
  <Wrapper>
    <Content columns={12}>
      <Message>{message}</Message>
      <Buttons>
        <StyledButton styling="link" onClick={reject.action}>
          {reject.label}
        </StyledButton>
        <StyledButton onClick={accept.action}>{accept.label}</StyledButton>
      </Buttons>
    </Content>
  </Wrapper>
);

const ButtonType = {
  label: string.isRequired,
  action: func.isRequired,
};

CookieWarning.propTypes = {
  message: node.isRequired,
  accept: shape(ButtonType).isRequired,
  reject: shape(ButtonType).isRequired,
};

export default CookieWarning;
