import styled from "@emotion/styled";
import gsap, { Power4 } from "gsap";
import { bool, node, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import TitleRepeater from "../TitleRepeater";

const Overlay = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:before {
    content: "";
    background-color: ${theme.color("modal.overlay")};
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Wrapper = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  outline: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  padding: ${theme.spacing("s")};
  ${mq("3")} {
    padding: ${theme.spacing("xl")};
  }
`;

const ModalElement = styled("div")`
  z-index: 100;
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleContainer = styled("div")`
  max-width: 750px;
  align-self: flex-start;
  margin-top: ${theme.spacing("xxl")};
  ${mq("3")} {
    margin-top: 0;
  }
`;

const Modal = ({ isShowing, title, closeButton, modalClassName, children }) => {
  const [show, setShow] = useState(false);

  const overlayElement = useRef();
  const contentElement = useRef();

  const timeline = useRef(gsap.timeline());

  useEffect(() => {
    const tl = timeline.current;
    if (show) {
      tl.to(
        overlayElement.current,
        {
          duration: 1,
          opacity: 1,
          ease: Power4.easeOut,
        },
        0
      );
      tl.to(
        contentElement.current,
        {
          duration: 1,
          opacity: 1,
          ease: Power4.easeOut,
          delay: 0.2,
        },
        0
      );
    }
    tl.play(0);
  }, [show]);

  useEffect(() => {
    setShow(isShowing);
  }, [isShowing]);

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Overlay ref={overlayElement} />

          <Wrapper
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
            ref={contentElement}
          >
            {closeButton}
            <TitleContainer>
              {title && <TitleRepeater title={title} />}
            </TitleContainer>
            <ModalElement className={modalClassName}>{children}</ModalElement>
          </Wrapper>
        </React.Fragment>,
        document.body
      )
    : null;
};

Modal.propTypes = {
  title: string,
  children: node.isRequired,
  closeButton: node.isRequired,
  isShowing: bool.isRequired,
  modalClassName: string,
  className: string,
};

export default Modal;
