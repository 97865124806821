import { string } from "prop-types";
import React from "react";

function Phone({ color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 36 36"
      className={`icon icon-arrow ${className}`}
      {...props}
    >
      <g>
        <path
          fill={color ? color : "currentColor"}
          d="M27.7,23.3L24.4,20c-0.6-0.6-1.6-0.6-2.2,0l-1.7,1.7c-1.6-0.9-2.9-1.7-4-2.8c-1.2-1.1-1.8-2-2.8-3.9l1.7-1.8
	c0.6-0.6,0.6-1.6,0-2.2l-3.4-3.3c-0.5-0.5-1.3-0.5-1.8,0l-1.1,1c-6.4,6.5,11,23.9,17.5,17.5l1-1.1C28.3,24.6,28.3,23.8,27.7,23.3z"
        />
      </g>
    </svg>
  );
}

Phone.propTypes = {
  color: string,
  className: string,
};

Phone.defaultProps = {
  color: null,
  className: "",
};

export default Phone;
