import { motion, useAnimation } from "framer-motion";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import theme from "../../theme";

/* eslint-disable */
function LogoName({ isSmall }) {
  const controls = useAnimation();
  const groupVariants = {
    textVisible: {
      transition: {
        delay: 0.3,
        staggerChildren: 0.05,
        duration: 0.5,
      },
    },
    textHidden: {
      transition: {
        delay: 0.3,
        staggerChildren: 0.05,
        duration: 0.5,
      },
    },
  };

  const lineVariants = {
    textVisible: {
      x: 0,
      transition: theme.get("animation.quickReveal.transition"),
    },
    textHidden: {
      x: -200,
      transition: theme.get("animation.quickReveal.transition"),
    },
  };

  useEffect(() => {
    controls.start(isSmall ? "textHidden" : "textVisible");
  }, [isSmall]);
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 344.49 153.18'>
      <defs>
        <clipPath id='clip-path'>
          <polygon points='138.36 6.48 169.78 61.41 118.74 150.52 115 156.83 349.21 156.83 349.21 0 134 0 138.36 6.48' />
        </clipPath>
      </defs>
      <path d='M166.78,61.41,155.19,41.12H139.36l8-13.64-12-21H112.14l-7.85,13.75L96.44,6.5H72.74L64.9,20.25,57.05,6.5H34l-12,21,8,13.64H14.27L2.68,61.41,14.45,81.94H30.28l-8,13.57h0l12,20.92H50.07l-8,13.6,11.75,20.49h23l7.84-13.75,7.85,13.75h23.23L127.48,130l-8-13.6h15.83l12-20.92-8-13.58h15.83ZM126.59,26.55A4.45,4.45,0,1,1,131,22.06v.05A4.44,4.44,0,0,1,126.59,26.55Z' />
      <motion.g
        animate={controls}
        variants={groupVariants}
        id='textgroup'
        clipPath='url(#clip-path)'
      >
        <motion.g variants={lineVariants} id='line1'>
          <path d='M216.29,23.26V49.51h-10l-1.61-4.34c-1.76,3.22-6,5-10.86,5-10.22,0-18.2-8.61-18.2-23.31C175.61,11.42,184.29,2,197.17,2c10,0,17.23,5.6,18.7,14.64L201,21.37c-.21-3.29-1.13-6.1-4-6.1s-5.25,3-5.25,11.63c0,6.79,1.61,10.64,5.39,10.64,2.66,0,3.86-1.47,3.86-3.71V33.2h-3.65V23.26Z' />
          <path d='M246.34,49.51A11.44,11.44,0,0,1,244.45,44l-.56-6c-.28-2-1.19-3-2.89-3h-3.09V49.51H222.39V2.74h21.22c10.29,0,17.15,4.27,17.15,14.36,0,6.3-3.92,10.29-8.26,11.41v.14c3.5.77,5.81,3,6.51,7l1.33,8.19a12,12,0,0,0,2.52,5.67ZM241.58,15.2h-3.72v8h3.72c2.1,0,3.15-1.12,3.15-4S243.82,15.2,241.58,15.2Z' />
          <path d='M304.49,49.21H268.88V2.44h35.47V15.19H284.07V20h18.31V31.43H284.07v5h20.42Z' />
        </motion.g>
        <motion.g variants={lineVariants} id='line1'>
          <path d='M347.17,102.38H311.56V55.61H347V68.35H326.76v4.76h18.31V84.6H326.76v5h20.41Z' />
          <path d='M214.74,102.38H179.13V55.61H214.6V68.35H194.33v4.76h18.31V84.6H194.33v5h20.41Z' />
          <path d='M247,55.76h14.63v46.77H247.94L236.88,82.36h-.21v20.17H222V55.76h14.36L246.82,76H247Z' />
          <path d='M297.5,78.72c5.67,1.12,8.68,5.32,8.68,10.78,0,7.64-5.11,13-16.18,13H268.86V55.76h21.56c9.18,0,14,4.9,14,12.46,0,5.81-2.73,9.24-6.93,10.36Zm-13.66-4.27h2.8c2,0,2.8-1.19,2.8-3.5s-.7-3.57-2.66-3.57h-2.94Zm0,16.52h3.71c2.1,0,2.81-1.26,2.81-3.57s-.84-3.57-2.88-3.57h-3.64Z' />
        </motion.g>
        <motion.g variants={lineVariants} id='line3'>
          <path d='M203.38,155.18a11.4,11.4,0,0,1-1.89-5.53l-.56-6c-.28-2-1.19-3.08-2.94-3.08h-3.08v14.56H179.44V108.41h21.21c10.29,0,17.16,4.27,17.16,14.36,0,6.3-3.93,10.29-8.27,11.41v.14c3.5.77,5.82,3,6.52,7l1.33,8.19a12,12,0,0,0,2.52,5.67Zm-4.76-34.3h-3.71v8h3.71c2.1,0,3.15-1.12,3.15-4s-.91-4-3.15-4Z' />
          <path d='M248.85,155.18a11.4,11.4,0,0,1-1.89-5.53l-.57-6c-.27-2-1.19-3.08-2.94-3.08h-3.08v14.56H224.9V108.41h21.22c10.29,0,17.15,4.27,17.15,14.36,0,6.3-3.92,10.29-8.26,11.41v.14c3.5.77,5.81,3,6.51,7l1.33,8.19a12,12,0,0,0,2.52,5.67Zm-4.77-34.3h-3.71v8h3.71c2.1,0,3.15-1.12,3.15-4S246.33,120.88,244.08,120.88Z' />
          <path d='M288.25,122.07l5-13.66H308.9L295.55,139.5v15.68H280.32V139.5l-13.45-31.09h16.2l5,13.66Z' />
        </motion.g>
      </motion.g>
    </svg>
  );
}
LogoName.prototype = {
  isSmall: PropTypes.bool,
};

/* eslint-enable */

export default LogoName;
