import DesignSystem, { pxTo } from "design-system-utils";

import colorPalette from "./colorPalette";

const baseFontSize = 16;
const defaultEase = [0.25, 0.9, 0.25, 1];
const tokens = {
  type: {
    baseFontSize: `${baseFontSize}px`,

    sizes: {
      base: 18,
      h1: 40,
      h2: 32,
      h3: 24,
      h4: 30,
      h5: 16,
      bodyLarge: 24,
      bodySmall: 20,
      caption: 16,
      ctaLarge: 20,
      ctaSmall: 16,
      bgTitle: 180,
      rotatingButton: 12,
      HeadingLarge: 32,

      desktop: {
        h1: 80,
        h2: 50,
        h3: 30,
        ctaLarge: 30,
        bgTitle: 300,
        HeadingLarge: 48,
      },

      buttonIcon: {
        width: 31,
        height: 19,
      },
      buttonIconSmall: {
        width: 24,
        height: 15,
      },
    },

    letterSpacing: {
      h1: 1,
      h2: 1,
      h5: 0.5,
      caption: 0.5,
      desktop: {
        h1: 2.5,
        h2: 1,
      },
    },

    lineHeight: {
      base: 18,
      h1: 40,
      h2: 32,
      h3: 32,
      h4: 35,
      h5: 19,
      bodyLarge: 32,
      bodySmall: 27,
      caption: 19,
      ctaLarge: 24,
      ctaSmall: 32,
      bgTitle: 180,
      rotatingButton: 14,
      HeadingLarge: 32,

      desktop: {
        h1: 80,
        h2: 50,
        h3: 30,
        ctaLarge: 32,
        bgTitle: 300,
        HeadingLarge: 48,
      },
    },

    fontFamily: {
      primary: '"National 2 Narrow"',
      secondary: '"Calibre"',
      tertiary: '"Greenberry"',
    },

    fontWeight: {
      primary: {
        regular: 400,
        bold: 700,
        black: 900,
      },
      secondary: {
        regular: 400,
        semiBold: 500,
      },
    },
  },

  colors: {
    colorPalette,
  },

  breakpoints: {
    1: "320px",
    2: "600px",
    3: "900px",
    4: "1200px",
    5: "1400px",
  },

  zIndex: {
    low: 10,
    mid: 100,
    high: 1000,
  },

  spacing: {
    baseline: 16,
    padding: "0.3em",
    scale: {
      base: pxTo(16, baseFontSize, "rem"),
      sm: pxTo(22, baseFontSize, "rem"),

      xxxs: "4px",
      xxs: "8px",
      xs: "16px",
      s: "24px",
      m: "32px",
      l: "44px",
      xl: "56px",
      xxl: "88px",
      xxxl: "120px",
      xxxxl: "140px",

      grid: {
        3: "317px",
        6: "634px",
        8: "848px",
        10: "1068px",
        12: "1280px",
      },
    },
  },

  layout: {
    gutter: pxTo(16, baseFontSize, "rem"),
    grid: {
      6: "634px",
      8: "848px",
      10: "1068px",
      12: "1280px",
      none: "100%",
    },
  },

  transition: {
    default: {
      duration: "300ms",
      timing: "cubic-bezier(0.7, 0, 0.2, 1)",
      transition: "all 300ms cubic-bezier(0.7, 0, 0.2, 1)",
    },
  },

  borderRadius: "8px",

  border: {
    width: "1px",
    styled: "solid",
    color: colorPalette.dark.light,
    default: `1px solid ${colorPalette.dark.light}`,
  },

  shadow: {
    x: "11px",
    y: "11px",
    blur: "44px",
    spread: "0",
    color: "rgba(0, 0, 0, 0.21)",
    default: "11px 11px 44px 0 rgba(0, 0, 0, 0.21)",
  },

  animation: {
    immediate: {
      duration: 0,
    },
    reveal: {
      transition: {
        ease: defaultEase,
        duration: 0.6,
      },
    },
    quickReveal: {
      transition: {
        ease: defaultEase,
        duration: 0.5,
      },
    },
    staggeredReveal: {
      transition: {
        ease: defaultEase,
        duration: 0.7,
        staggerChildren: 0.15,
      },
    },
    staggeredQuickReveal: {
      transition: {
        ease: defaultEase,
        duration: 0.9,
        staggerChildren: 0.08,
      },
    },
    staggeredHide: {
      transition: {
        ease: defaultEase,
        duration: 0.7,
        staggerChildren: 0.15,
        staggerDirection: -1,
      },
    },
    hover: {
      transition: {
        ease: defaultEase,
        duration: 0.3,
      },
    },
  },
};

class CustomDesignSystem extends DesignSystem {
  /**
   * Returns the letter-spacing base on a given value.
   * @param {string} value
   */
  letterspacing(value) {
    return pxTo(
      this.get(`type.letterSpacing.${value}`),
      parseInt(this.get("type.baseFontSize")),
      "rem"
    );
  }

  /**
   * Returns the font-size base on a given value.
   * @param {string} value
   */
  fs(value) {
    return pxTo(
      this.get(`type.sizes.${value}`),
      parseInt(this.get("type.baseFontSize")),
      "rem"
    );
  }
  /**
   * Returns the line-height base on a given value.
   * @param {string} value
   */
  lh(value) {
    return pxTo(
      this.get(`type.lineHeight.${value}`),
      parseInt(this.get("type.baseFontSize")),
      "rem"
    );
  }

  /**
   * Returns a font-family based on a given value.
   * @param {string} value
   */
  family(value) {
    return this.get(`type.fontFamily.${value}`);
  }

  /**
   * Returns the default transition or a specific transition property based on a given value.
   * @param {string} value
   */
  transition(value = "transition") {
    return this.get(`transition.default.${value}`);
  }

  /**
   * Returns a font weight based on a given value.
   * @param {string} value
   */
  weight(value) {
    return this.get(`type.fontWeight.${value}`);
  }

  /**
   * Returns the default shadow or a specific shadow property based on a given value.
   * @param {string} value
   */
  shadow(value = "default") {
    return this.get(`shadow.${value}`);
  }

  /**
   * Returns the default border or a specific border property based on a given value.
   * @param {string} value
   */
  border(value = "default") {
    return this.get(`border.${value}`);
  }
}

export default new CustomDesignSystem(tokens, {
  fontSizeUnit: "rem",
});
