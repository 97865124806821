import styled from "@emotion/styled";

import theme from "../../theme";

const Form = styled.form`
  > * {
    margin-bottom: ${theme.space("s")};
  }
`;

export default Form;
