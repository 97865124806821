import { useEffect, useState } from "react";

const getBrowserCookie = (name) => {
  if (typeof document === "undefined") {
    return;
  }
  const decodedCookies = decodeURIComponent(document.cookie);
  const storedCookies = decodedCookies.split(";");
  return storedCookies.find((cookie) => cookie.includes(name));
};

const setBrowserCookie = (name, value, expiration) => {
  const now = new Date();
  const expires = new Date(
    now.setTime(now.getTime() + expiration * 24 * 60 * 60 * 1000)
  );

  document.cookie = `${name}=${value};expires=${expires.toUTCString()}`;
};

/**
 * Handles cookies
 * @param {string} name - name of the cookie
 * @param {any} [value] - value to be stored
 * @param {number} [expiration] - number of days cookie is valid
 * @returns {[string, function]}
 */
const useCookies = (name, value, expiration) => {
  const browserCookie = getBrowserCookie(name);
  const [localCookie, setLocalCookie] = useState(browserCookie);

  const handleSetCookie = (name, value, expiration) => {
    setBrowserCookie(name, value, expiration);
    setLocalCookie(`${name}=${value}`);
  };

  useEffect(() => {
    if (browserCookie !== "undefined" && browserCookie !== localCookie) {
      handleSetCookie(name, value, expiration);
    }
  }, [browserCookie, localCookie, name, value, expiration]);

  return [localCookie, handleSetCookie];
};

export default useCookies;
