import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { bool, func, node, oneOf, string } from "prop-types";
import React from "react";

import theme from "../../theme";
import hexToRGBA from "../../utils/hexToRgba";
import { ctaLarge, ctaSmall, h5 } from "../Typography";

const defaults = ({ alignLeft }) => css`
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  text-align: ${alignLeft ? "left" : "center"};
  transition: ${theme.get("transition.default.transition")};
  text-decoration: none;
  &:disabled {
    opacity: 0.2;
    cursor: auto;
    pointer-events: none;
  }
  &:focus {
    outline: none;
  }
  .icon {
    height: ${theme.fs("buttonIcon.height")};
    width: ${theme.fs("buttonIcon.width")};
  }
`;

const buttonStyle = ({ children }) => css`
  ${h5};
  padding: ${React.Children.count(children) === 1 ? "18px 50px" : "14px 50px"};
  background-color: ${theme.color("primary")};
  color: ${theme.color("text.light")};
  min-width: 164px;

  &:hover,
  &:active {
    background-color: ${theme.color("primary.light")};
    color: ${theme.color("primary")};
  }

  .icon {
    margin-left: 12px;
  }
`;

const linkStyle = ({ size, inverted, color }) => css`
  ${size === "small" ? ctaSmall : ctaLarge};
  ${size === "small" &&
  `
    .icon {
      height: ${theme.fs("buttonIconSmall.height")};
      width: ${theme.fs("buttonIconSmall.width")};
    }
  `};

  padding-left: 0;
  background-color: transparent;
  color: ${inverted ? theme.color("background") : theme.color(color)};

  &:hover,
  &:active {
    color: ${inverted
      ? hexToRGBA(0.5, theme.color("background"))
      : theme.color(`${color}.light`)};
  }

  & > *:last-child:not(:only-child) {
    margin-left: 20px;
  }
`;

const StyledButton = styled.button`
  ${defaults};
  ${buttonStyle};
`;

const StyledLink = styled.button`
  ${defaults};
  ${linkStyle};
`;

const buttonType = {
  button: StyledButton,
  link: StyledLink,
};

const Button = ({
  children,
  onClick,
  size,
  styling,
  color,
  ariaLabel,
  alignLeft,
  ...props
}) => {
  const Comp = buttonType[styling];
  return (
    <Comp
      {...props}
      onClick={onClick}
      size={size}
      color={color}
      aria-label={ariaLabel}
      alignLeft={alignLeft}
    >
      {children}
    </Comp>
  );
};

Button.propTypes = {
  children: node.isRequired,
  onClick: func,
  size: oneOf(["small", "large"]),
  color: oneOf(["primary", "secondary"]),
  ariaLabel: string,
  styling: oneOf(["button", "link"]),
  inverted: bool,
  alignLeft: bool,
};

Button.defaultProps = {
  styling: "button",
  size: "small",
  color: "primary",
  inverted: false,
  alignLeft: false,
};

export default Button;
