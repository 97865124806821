import styled from "@emotion/styled";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";

const Wrapper = styled.div`
  position: relative;
  box-sizing: content-box;
  margin: ${theme.spacing("s")} auto;
  padding: 0 ${({ padding }) => (padding ? padding : theme.spacing("sm"))};

  ${mq("2")} {
    max-width: ${({ columns }) =>
      columns ? theme.spacing(`grid[${columns}]`) : theme.spacing("grid[10]")};
  }
`;

export default Wrapper;
