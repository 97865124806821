import React from "react";

import Link from "../../components/Link";

const createLink = (path) =>
  function LinkWithPath(props) {
    return <Link to={path} {...props} />;
  };

export default createLink;
