import styled from "@emotion/styled";
import { string } from "prop-types";
import React from "react";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import Heading from "../Heading";

const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleRepeatWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RepeatedHeadingWrapper = styled.div`
  height: calc(${theme.fs("h1")} * 0.875);
  overflow: hidden;

  ${mq("3")} {
    height: calc(${theme.lh("desktop.h1")} * 0.8);
  }
`;

const StyledFirstHeading = styled(Heading)`
  line-height: 0.8 !important;
  color: #fff;
`;

const StyledStrokedHeading = styled(StyledFirstHeading)`
  line-height: 0.8 !important;
  -webkit-text-stroke: 1px #fff;
  text-stroke: 1px #fff;
  -webkit-text-fill-color: transparent;
  text-fill: transparent;
`;

function TitleRepeater({ title, ...props }) {
  return (
    <TitleWrapper {...props}>
      <TitleRepeatWrapper>
        {[...Array(4)].map((x, i) => (
          <RepeatedHeadingWrapper key={i}>
            <StyledStrokedHeading
              as="span"
              size={1}
              style={{ opacity: (i + 1) * 0.25 }}
            >
              {title}
            </StyledStrokedHeading>
          </RepeatedHeadingWrapper>
        ))}
      </TitleRepeatWrapper>
      <StyledFirstHeading size={1}>
        <span>{title}</span>
      </StyledFirstHeading>
    </TitleWrapper>
  );
}

TitleRepeater.propTypes = {
  title: string.isRequired,
};
export default TitleRepeater;
