import { string } from "prop-types";
import React from "react";

function Facebook({ color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 36 36"
      className={`icon icon-arrow ${className}`}
      {...props}
    >
      <g transform="translate(12.0, 6.0)">
        <path
          fill={color ? color : "currentColor"}
          d="M24.19,10.49h-2c-1.6,0-1.91.79-1.91,1.94V15h3.81l-.49,4H20.24V29.25h-4V19H12.94V15h3.32V12c0-3.43,2-5.29,5-5.29a26,26,0,0,1,3,.16Z"
          transform="translate(-12.94 -6.75)"
        />
      </g>
    </svg>
  );
}

Facebook.propTypes = {
  color: string,
  className: string,
};

Facebook.defaultProps = {
  color: null,
  className: "",
};

export default Facebook;
